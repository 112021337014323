import React from "react"
import styled from "styled-components"
import PurchaseButton from "../buttons/PurchaseButton"
import { themes } from "../styles/ColorStyles"
import { H1, H2, H4, MediumText } from "../styles/TextStyles"

function HeroSection() {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>
            Welcome <br />
            to the <br />
            CLUBHΔU5
          </Title>
          <Description>Coming Soon...</Description>
          {/*<PurchaseButton title="Get Fitted" subtitle="Explore the line" />*/}
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default HeroSection

const Wrapper = styled.div`
  background: linear-gradient(
    -45deg,
    hsla(240, 64%, 27%, 1) 0%,
    hsla(251, 57%, 23%, 1) 100%
  );
`

const ContentWrapper = styled.div`
  max-width: 1234px;
  padding: 200px 30px;
  margin: 0 auto;
`

const TextWrapper = styled.div`
  max-width: 360px;
  display: grid;
  gap: 30px;
`

const Title = styled(H1)`
  color: #e6eb4c /*${themes.dark.text1}*/;
`

const CompanyName = styled(H2)`
  color: ${themes.dark.text1};
`

const Description = styled(H4)`
  color: ${themes.dark.text1};
  background: rgba(118, 120, 237, 0.38);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 11px;
  height: 45px;
  padding-left: 8px;
  line-height: 45px;
  width: fit-content;
  padding-right: 14px;
`
